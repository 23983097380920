import React, { useState, useEffect } from 'react';
import { Container, Card, Table, Button, Modal, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import 'react-quill/dist/quill.snow.css';

export default function KnowledgeArticleList() {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  // Campos para o modal
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentArticle, setCurrentArticle] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    video_url: '',
    category_id: '',
  });

  // Campo de busca
  const [searchText, setSearchText] = useState('');

  // Carrega os artigos
  const fetchArticles = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/knowledge');
      setArticles(response.data);
    } catch (error) {
      console.error('Erro ao carregar artigos:', error);
    } finally {
      setLoading(false);
    }
  };

  // Carrega as categorias
  const fetchCategories = async () => {
    try {
      const response = await axios.get('/knowleadgecategories');
      setCategories(response.data);
    } catch (error) {
      console.error('Erro ao carregar categorias:', error);
    }
  };

  useEffect(() => {
    fetchArticles();
    fetchCategories();
  }, []);

  // Filtro local dos artigos, baseado em título e nome da categoria
  const filteredArticles = articles.filter((article) => {
    const lowerSearch = searchText.toLowerCase();
    const title = article.title.toLowerCase();
    const categoryName = article.category
      ? article.category.name.toLowerCase()
      : '';
    return title.includes(lowerSearch) || categoryName.includes(lowerSearch);
  });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setEditMode(false);
    setCurrentArticle(null);
    setFormData({ title: '', content: '', video_url: '', category_id: '' });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleContentChange = (value) => {
    setFormData((prev) => ({ ...prev, content: value }));
  };

  const handleSaveArticle = async () => {
    setLoading(true);
    try {
      if (editMode && currentArticle) {
        await axios.put(`/knowledge/${currentArticle.id}`, formData);
      } else {
        await axios.post('/knowledge', formData);
      }
      fetchArticles();
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao salvar artigo:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (article) => {
    setCurrentArticle(article);
    setFormData({
      title: article.title,
      content: article.content,
      video_url: article.video_url,
      category_id: article.category_id || '',
    });
    setEditMode(true);
    handleShowModal();
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`/knowledge/${id}`);
      fetchArticles();
    } catch (error) {
      console.error('Erro ao deletar artigo:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Breadcrumbs title="Base de Conhecimento - Artigos" />

      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h1 className="page-heading">Artigos</h1>
          <Button variant="success" onClick={handleShowModal}>
            + Artigo
          </Button>
        </Card.Header>

        <Card.Body>
          {loading && <Loading />}

          {/* Campo de Pesquisa */}
          <Form.Group className="mb-3" controlId="searchText">
            <Form.Label>
              <strong>Pesquisar</strong>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite um título ou categoria..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              style={{ maxWidth: '250px' }}
            />
          </Form.Group>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Título</th>
                <th>Categoria</th>
                <th>Vídeo</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {filteredArticles.map((article) => (
                <tr key={article.id}>
                  <td>{article.title}</td>
                  <td>{article.category ? article.category.name : '-'}</td>
                  <td>
                    {article.video_url ? (
                      <a
                        href={article.video_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Visualizar
                      </a>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    <Button
                      variant="primary"
                      className="me-2"
                      onClick={() => handleEdit(article)}
                    >
                      Visualizar - Editar
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(article.id)}
                    >
                      Remover
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? 'Editar Artigo' : 'Adicionar Artigo'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* TÍTULO */}
            <Form.Group className="mb-3">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
              />
            </Form.Group>

            {/* CONTEÚDO */}
            <Form.Group className="mb-3">
              <Form.Label>Conteúdo</Form.Label>
              <ReactQuill
                value={formData.content}
                onChange={handleContentChange}
                style={{ height: '100%' }} // Ajuste a altura do editor
              />
            </Form.Group>

            {/* VIDEO URL */}
            <Form.Group className="mb-3">
              <Form.Label>Video URL</Form.Label>
              <Form.Control
                type="text"
                name="video_url"
                value={formData.video_url}
                onChange={handleInputChange}
              />
            </Form.Group>

            {/* CATEGORIA */}
            <Form.Group className="mb-3">
              <Form.Label>Categoria</Form.Label>
              <Form.Select
                name="category_id"
                value={formData.category_id}
                onChange={handleInputChange}
              >
                <option value="">--Selecione--</option>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="success" onClick={handleSaveArticle}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
