/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { FaUserCircle, FaPlus } from 'react-icons/fa';
import { Container, Card, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import DataTable from '../../components/DataTable';
import Avatar from '../../components/Avatar';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import { useNotification } from '../../components/Notification/NotificationContext';
import ErrorProcessor from '../../components/ErrorProcessor';
import CardHeaderMore from '../../components/Cards/CardHeaderMore';

// URL base da API, onde as imagens estão sendo servidas.
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Users() {
  const routeName = '/users';
  const dataName = 'Usuário';
  const titleName = 'Usuários';
  const navigate = useNavigate();
  const notification = useNotification();
  const [apiErrors, setApiErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.auth.user);

  // State para armazenar o termo de pesquisa
  const [searchTerm, setSearchTerm] = useState('');

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Sobrenome',
        accessor: 'lastname',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'Empresas',
        accessor: 'company',
        noSearch: true,
      },
      {
        Header: 'Acessos',
        accessor: 'access',
        noSearch: true,
      },
    ],
    []
  );

  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);
        const response = await axios.get(routeName);
        setUsers(response.data);
      } catch (err) {
        setApiErrors(err);
        setUsers([]);
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, []);

  const confirmDelete = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        await axios.delete(`${routeName}/${id}`);
        notification(`${dataName} removido com sucesso`, 'success');
        const updatedUsers = users.filter((user) => user.id !== id);
        setUsers(updatedUsers);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    [users, notification]
  );

  const resetPass = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        const newPass = await axios.put(`${routeName}/${id}/resetpass`);
        notification(
          <div>
            Nova senha gerada: <b>{newPass.data.newPass}</b>
          </div>,
          'info',
          50000
        );
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    [notification]
  );

  // Filtra os usuários conforme o termo de pesquisa
  const filteredUsers = useMemo(() => {
    if (!searchTerm) return users;
    const lowerTerm = searchTerm.toLowerCase();
    return users.filter((user) => {
      // Considere que o perfil do usuário pode estar em user.UserProfile
      const userName = get(user, 'UserProfile.name', '');
      const userLastname = get(user, 'UserProfile.lastname', '');
      const email = user.email || '';
      return (
        userName.toLowerCase().includes(lowerTerm) ||
        userLastname.toLowerCase().includes(lowerTerm) ||
        email.toLowerCase().includes(lowerTerm)
      );
    });
  }, [searchTerm, users]);

  // Mapeia os usuários filtrados para os dados da tabela
  const tableData = useMemo(() => {
    return filteredUsers.map((dataObject) => {
      const userProfile = dataObject.UserProfile || {};
      const name = userProfile.name || 'Não especificado';
      const lastname = userProfile.lastname || 'Não especificado';
      // Caminho da imagem de perfil
      const photoPath = get(dataObject, 'UserProfile.photo', '');
      const photoUrl = photoPath
        ? `${API_BASE_URL}${photoPath}` // monta o caminho completo
        : '';

      const linksAction = [
        {
          custom: (
            <ButtonModal
              onConfirm={() => resetPass(dataObject.id)}
              message={`Confirma redefinição de senha do usuário ${dataObject.email}?`}
              buttonProps={{ text: 'Redefinir senha', faicon: 'faLock' }}
              textModal={{ title: 'Redefinir senha', textConfirm: 'Redefinir' }}
              dropdown
            />
          ),
        },
        {
          onclick: () => {
            navigate(`${routeName}/${dataObject.id}/edit`);
          },
          faicon: 'faEdit',
          text: 'Atualizar',
        },
      ];

      if (userData.isadmin === 1) {
        linksAction.push({
          custom: (
            <ButtonModal
              onConfirm={() => confirmDelete(dataObject.id)}
              message={`Confirma remoção da ${dataName} ${dataObject.email}?`}
              buttonProps={{ text: 'Remover', faicon: 'faTrash' }}
              textModal={{
                title: `Remover ${dataName}`,
                textConfirm: 'Remover',
              }}
              dropdown
            />
          ),
        });
      }

      return {
        photo: photoUrl ? (
          <Avatar image={photoUrl} alt="Foto de perfil" size="sm" priority />
        ) : (
          <FaUserCircle size={36} />
        ),
        company: (
          <Link to={`/users/${dataObject.id}/company`}>
            <Button
              variant="success"
              className="rounded-pill shadow text-white"
              size="sm"
            >
              <b>{Object.values(dataObject.Companies).length}</b>
            </Button>
          </Link>
        ),
        access: (
          <div className="d-flex justify-content-between">
            <div>
              <Link to={`/users/${dataObject.id}/access`}>
                <Button
                  variant="success"
                  className="rounded-pill shadow text-white"
                  size="sm"
                >
                  <b>{Object.values(dataObject.Accesses).length}</b>
                </Button>
              </Link>
            </div>
            <CardHeaderMore links={linksAction} className="" />
          </div>
        ),
        id: dataObject.id,
        name,
        lastname,
        email: dataObject.email,
      };
    });
  }, [filteredUsers, confirmDelete, resetPass, userData, navigate]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs title="Usuários" />
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <h1 className="page-heading">{titleName}</h1>
        <div className="d-flex align-items-center">
          {/* Campo de pesquisa */}
          <Form.Control
            type="text"
            placeholder="Pesquisar usuários..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginRight: '1rem', maxWidth: '250px' }}
          />
          <Link to={`${routeName}/new`}>
            <Button variant="primary" className="text-uppercase">
              <FaPlus /> Cadastrar
            </Button>
          </Link>
        </div>
      </div>
      <section>
        <Card className="card-table mb-5">
          <ErrorProcessor error={apiErrors} />
          <DataTable
            items={tableData}
            columns={tableColumns}
            defaultPageSize={5}
          />
        </Card>
      </section>
    </Container>
  );
}
