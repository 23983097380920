/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Card, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FaPlus } from 'react-icons/fa';

import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import DataTable from '../../components/DataTable';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import { useNotification } from '../../components/Notification/NotificationContext';
import ErrorProcessor from '../../components/ErrorProcessor';
import CardHeaderMore from '../../components/Cards/CardHeaderMore';

const formatTelefone = (telefone) => {
  if (!telefone) return '';
  const ddd = telefone.slice(0, 2);
  const prefix = telefone.slice(2, 6);
  const suffix = telefone.slice(6);
  return `(${ddd}) ${prefix}-${suffix}`;
};

const formatCelular = (celular) => {
  if (!celular) return '';
  const ddd = celular.slice(0, 2);
  const prefix = celular.slice(2, 7);
  const suffix = celular.slice(7);
  return `(${ddd}) ${prefix}-${suffix}`;
};

export default function Requesters() {
  const routeName = '/requesters';
  const dataName = 'Solicitante';
  const titleName = 'Solicitantes';
  const navigate = useNavigate();
  const notification = useNotification();
  const userData = useSelector((state) => state.auth.user);

  const [apiErrors, setApiErrors] = useState({});
  const [requesters, setRequesters] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // State para armazenar o termo de pesquisa
  const [searchText, setSearchText] = useState('');

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'Empresa',
        accessor: 'companyName',
      },
      {
        Header: 'Telefone',
        accessor: 'telefone',
        noSearch: true,
      },
      {
        Header: 'Celular',
        accessor: 'celular',
        noSearch: true,
      },
      {
        Header: 'Ramal',
        accessor: 'ramal',
        noSearch: true,
      },
      {
        Header: 'Ações',
        accessor: 'actions',
        noSearch: true,
        disableSortBy: true,
      },
    ],
    []
  );

  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);
        const response = await axios.get(routeName);
        setRequesters(response.data);
        const companiesResponse = await axios.get('/customers');
        setCompanies(companiesResponse.data);
      } catch (err) {
        setApiErrors(err);
        setRequesters([]);
        setCompanies([]);
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [notification]);

  const confirmDelete = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        await axios.delete(`${routeName}/${id}`);
        notification(`${dataName} removido com sucesso`, 'success');
        const updatedRequesters = requesters.filter(
          (requester) => requester.id !== id
        );
        setRequesters(updatedRequesters);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    [requesters, notification]
  );

  // Filtra os solicitantes com base no termo de pesquisa
  const filteredRequesters = useMemo(() => {
    if (!searchText) return requesters;
    const lowerSearch = searchText.toLowerCase();
    return requesters.filter((requester) => {
      const companyData = companies.find(
        (comp) => comp.id === requester.company
      );
      const companyName = companyData ? companyData.razaoSocial : '';
      return (
        (requester.name &&
          requester.name.toLowerCase().includes(lowerSearch)) ||
        (requester.email &&
          requester.email.toLowerCase().includes(lowerSearch)) ||
        (companyName && companyName.toLowerCase().includes(lowerSearch)) ||
        (requester.telefone &&
          requester.telefone.toLowerCase().includes(lowerSearch)) ||
        (requester.celular &&
          requester.celular.toLowerCase().includes(lowerSearch)) ||
        (requester.ramal && requester.ramal.toLowerCase().includes(lowerSearch))
      );
    });
  }, [requesters, companies, searchText]);

  const tableData = useMemo(() => {
    return filteredRequesters.map((dataObject) => {
      const companyData = companies.find(
        (comp) => comp.id === dataObject.company
      );
      const linksAction = [
        {
          onclick: () => {
            navigate(`${routeName}/${dataObject.id}/edit`);
          },
          faicon: 'faEdit',
          text: 'Detalhes',
        },
      ];

      if (userData.isadmin === 1) {
        linksAction.push({
          custom: (
            <ButtonModal
              onConfirm={() => confirmDelete(dataObject.id)}
              message={`Confirma remoção do ${dataName} ${dataObject.name}?`}
              buttonProps={{ text: 'Remover', faicon: 'faTrash' }}
              textModal={{
                title: `Remover ${dataName}`,
                textConfirm: 'Remover',
              }}
              dropdown
            />
          ),
        });
      }

      return {
        name: dataObject.name,
        email: dataObject.email,
        companyName: companyData ? companyData.razaoSocial : 'N/A',
        telefone: formatTelefone(dataObject.telefone),
        celular: formatCelular(dataObject.celular),
        ramal: dataObject.ramal,
        actions: <CardHeaderMore links={linksAction} className="" />,
      };
    });
  }, [filteredRequesters, companies, userData, confirmDelete, navigate]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs title="Solicitantes" />
      <div className="page-header d-flex justify-content-between align-items-center">
        <h1 className="page-heading">{titleName}</h1>
        <div>
          <Link to={`${routeName}/new`}>
            <Button variant="primary" className="text-uppercase">
              <FaPlus /> Cadastrar
            </Button>
          </Link>
        </div>
      </div>

      {/* Campo de pesquisa com largura reduzida */}
      <Form.Group controlId="search" className="mb-3">
        <strong>Pesquisar</strong>
        <Form.Control
          type="search"
          placeholder="Digite aqui..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ maxWidth: '150px' }}
        />
      </Form.Group>

      <section>
        <Card className="card-table mb-5">
          <ErrorProcessor error={apiErrors} />
          <DataTable
            items={tableData}
            columns={tableColumns}
            defaultPageSize={5}
          />
        </Card>
      </section>
    </Container>
  );
}
