import React, { useState, useEffect, useCallback } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { get } from 'lodash';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import Loading from '../../components/Loading';
import Image from '../../components/CustomImage';
import axios from '../../services/axios';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useNotification } from '../../components/Notification/NotificationContext';
import Forms from '../../components/Forms';
import ErrorProcessor from '../../components/ErrorProcessor';

// URL base da API (usada para compor o caminho da foto)
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function User() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const notification = useNotification();
  const navigate = useNavigate();
  const params = useParams();
  const id = get(params, 'id', '');
  const [isLoading, setIsLoading] = useState(false);
  const [photo, setPhoto] = useState('');
  const [companies, setCompanies] = useState([]);

  // Obtemos os dados do usuário logado (exemplo: { id, isadmin, empresa_id, ... })
  const userData = useSelector((state) => state.auth.user);

  // No estado inicial, se o usuário NÃO for admin, já preenchemos o companyId com a empresa do usuário logado.
  const [formValues, setFormValues] = useState({
    name: '',
    lastname: '',
    email: '',
    ramal: '',
    password: '',
    companyId: userData && userData.isadmin === 1 ? '' : userData.empresa_id,
  });

  // Esquema de validação do formulário.
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Nome deve conter no mínimo 3 caracteres.')
      .required('Nome obrigatório.'),
    lastname: Yup.string()
      .min(3, 'Sobrenome deve conter no mínimo 3 caracteres.')
      .required('Sobrenome obrigatório.'),
    ramal: Yup.string()
      .min(3, 'Ramal deve conter no mínimo 3 caracteres.')
      .required('Ramal obrigatório.'),
    email: !id
      ? Yup.string().required('E-mail obrigatório.').email('E-mail inválido.')
      : Yup.string(),
    password: !id
      ? Yup.string()
          .min(6, 'Senha deve conter no mínimo 6 caracteres.')
          .required('Senha obrigatória.')
      : Yup.string(),
    confirmPassword: !id
      ? Yup.string()
          .oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais.')
          .required('Confirme sua senha.')
      : Yup.string(),
    // Se o usuário logado for admin e for criação, torna o campo obrigatório
    ...(!id && userData && userData.isadmin === 1
      ? { companyId: Yup.string().required('Selecione uma empresa.') }
      : {}),
  });

  // Função para atualizar os valores do formulário
  const handleFieldChange = useCallback((fieldName, value) => {
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
  }, []);

  // Configuração dos campos do formulário
  const fields = [
    {
      name: 'name',
      id: 'name',
      label: 'Nome',
      type: 'text',
      onChange: (e) => handleFieldChange('name', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
    {
      name: 'lastname',
      id: 'lastname',
      label: 'Sobrenome',
      type: 'text',
      onChange: (e) => handleFieldChange('lastname', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
  ];

  // Caso seja criação (sem id), adicionamos os demais campos
  if (!id) {
    fields.push({
      name: 'email',
      id: 'email',
      label: 'E-mail',
      type: 'email',
      groupText: '@',
      onChange: (e) => handleFieldChange('email', e.target.value),
      colSize: 4,
      additionalProps: {},
    });
    fields.push({
      name: 'ramal',
      id: 'ramal',
      label: 'Ramal',
      type: 'text',
      onChange: (e) => handleFieldChange('ramal', e.target.value),
      colSize: 4,
      additionalProps: {},
    });
    fields.push({
      name: 'password',
      id: 'password',
      label: 'Senha',
      type: 'password',
      onChange: (e) => handleFieldChange('password', e.target.value),
      colSize: 4,
      additionalProps: {},
    });
    fields.push({
      name: 'confirmPassword',
      id: 'confirmPassword',
      label: 'Confirme a senha',
      type: 'password',
      onChange: (e) => handleFieldChange('confirmPassword', e.target.value),
      colSize: 4,
      additionalProps: {},
    });

    // Se o usuário logado for admin, exibe o campo para selecionar a empresa
    if (userData && userData.isadmin === 1) {
      fields.push({
        name: 'companyId',
        id: 'companyId',
        label: 'Empresa',
        type: 'select',
        options: [
          { value: '', label: '-- Selecione --' },
          ...companies.map((company) => ({
            value: company.id,
            label: company.name,
          })),
        ],
        // Lembre que alguns componentes de select podem enviar o valor via e.target.value ou e.value
        onChange: (e) =>
          handleFieldChange('companyId', e.value ? e.value : e.target.value),
        colSize: 4,
        additionalProps: {},
      });
    }
  }

  // Função de envio do formulário
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let action = 'cadastrado';

      // Se for criação e o usuário logado for admin, verifica se a empresa foi selecionada
      if (!id && userData && userData.isadmin === 1 && !formValues.companyId) {
        setApiErrors({ error: 'Selecione uma empresa antes de continuar.' });
        setIsLoading(false);
        return;
      }

      // Mapeia os dados do formulário para o payload esperado pela API.
      // Se o usuário logado não for admin, utiliza a empresa do próprio usuário.
      const payload = id
        ? { ...formValues }
        : {
            ...formValues,
            empresa_id:
              userData && userData.isadmin === 1
                ? formValues.companyId
                : userData.empresa_id,
          };

      if (id) {
        await axios.put(`/profiles/${id}`, payload);
        action = 'atualizado';
      } else {
        const getUser = await axios.post(`/users/`, payload);
        const createProfile = { ...payload, user_id: getUser.data.id };
        await axios.post(`/profiles/`, createProfile);
      }
      notification(`Usuário ${action} com sucesso`, 'success');
      navigate('/users');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Caso o usuário logado seja admin e seja criação, busca as empresas para o select
  useEffect(() => {
    async function fetchCompanies() {
      try {
        const { data } = await axios.get('/companies');
        setCompanies(data);
      } catch (err) {
        setApiErrors(err);
      }
    }
    if (!id && userData && userData.isadmin === 1) {
      fetchCompanies();
    }
  }, [id, userData]);

  // Quando for atualização, carrega os dados do usuário
  useEffect(() => {
    if (!id) return;
    async function getData() {
      try {
        setIsLoading(true);
        const { data } = await axios.get(`users/${id}`);

        // Concatena o caminho da API com o caminho relativo da foto
        const photoPath = get(data, 'UserProfile.photo', '');
        const photoUrl = photoPath ? `${API_BASE_URL}${photoPath}` : '';
        setPhoto(photoUrl);

        setFormValues({
          ...data,
          name: data.UserProfile.name,
          lastname: data.UserProfile.lastname,
          ramal: data.UserProfile.ramal,
          email: data.email,
          companyId: data.empresa_id, // Empresa já associada ao usuário
        });
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
        setDataLoaded(true);
      }
    }
    getData();
  }, [id, notification, navigate]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs
        title={id ? 'Atualizar' : 'Cadastrar'}
        pages={[{ link: '/users', name: 'Usuários' }]}
      />
      <section>
        <Row>
          {id && (
            <Col lg={4}>
              <Card className="card-profile mb-4">
                <Card.Header
                  className="overflow-hidden"
                  style={{
                    backgroundImage: `url('/img/photos/greenShapes.jpg')`,
                  }}
                />
                <Card.Body className="text-center">
                  <div
                    className="card-profile-img mx-auto d-flex align-center"
                    style={{ maxHeight: '8rem' }}
                  >
                    <div className="position-relative overflow-hidden rounded-circle">
                      <Link
                        to={`/profile/${id}`}
                        title="Clique para alterar a foto"
                      >
                        <Image
                          src={photo || '/img/user-profile-circle.png'}
                          layout="fixed"
                          width={122}
                          height={122}
                          priority
                          alt={formValues.name}
                        />
                      </Link>
                    </div>
                  </div>
                  <h3 className="mb-3">{`${formValues.name} ${formValues.lastname}`}</h3>
                  <p className="mb-4">{formValues.email}</p>
                </Card.Body>
              </Card>
            </Col>
          )}
          <Col lg={id ? 8 : 12}>
            <Card className="mb-5">
              <Card.Header>
                <h4 className="card-heading">
                  {id ? 'Atualizar usuário' : 'Cadastrar usuário'}
                </h4>
              </Card.Header>
              <Card.Body>
                <ErrorProcessor error={apiErrors} />
                {(!id || dataLoaded) && (
                  <Forms
                    initialValues={formValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    fields={fields}
                    btnSubmit={{
                      variant: 'primary',
                      type: 'submit',
                      text: id ? 'Atualizar' : 'Cadastrar',
                    }}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
