import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Login from '../pages/Login';
import Page404 from '../pages/Page404';
import Page403 from '../pages/Page403';
import Users from '../pages/Users';
import User from '../pages/Users/User';
import UserProfile from '../pages/Users/UserProfile';
import UserCompany from '../pages/Users/UserCompany';
import Companies from '../pages/Companies';
import Company from '../pages/Companies/Company';
import AccessCompany from '../pages/Companies/AccessCompany';
import Accesses from '../pages/Accesses';
import Access from '../pages/Accesses/Access';
import AccessModule from '../pages/Accesses/AccessModule';
import AccessUser from '../pages/Users/AccessUser';
import Channels from '../pages/Channels';
import Channel from '../pages/Channels/Channel';
import Classifiers from '../pages/Classifiers';
import Classifier from '../pages/Classifiers/Classifier';
import Customers from '../pages/Customers';
import Customer from '../pages/Customers/Customer';
import Leads from '../pages/Leads';
import Tickets from '../pages/Tickets/index';
import Ticket from '../pages/Tickets/tickets';
import TicketAdd from '../pages/Tickets/ticketsAdd';
import Lead from '../pages/Leads/Lead';
import Home from '../pages/Home';
import EmailProvider from '../pages/EmailProvider/EmailProvider';
import EmailsProviders from '../pages/EmailProvider';
import ResetPassword from '../pages/Login/ResetPassword';
import Departaments from '../pages/Departament/index';
import Departament from '../pages/Departament/Departament';
import AccessDepartament from '../pages/Departament/UserDepartament';
import Requesters from '../pages/Requesters';
import Requester from '../pages/Requesters/Requesters';
import RequesterEdit from '../pages/Requesters/RequestersEdit';
import EmailTeamplates from '../pages/Templates/emailTemplates';
import ServiceDesk from '../pages/ServiceDesk/ServiceDesk';
import ServiceDeskEdit from '../pages/ServiceDesk/ServiceDeskEdit';
import ServiceDeskList from '../pages/ServiceDesk/index';
import PriorityList from '../pages/Priority/PriorityList';
import StagesList from '../pages/Stages/StagesList';
import MessageTeamplate from '../pages/MessageTemplates/MessageTemplates';
import Reports from '../pages/Reports/Reports';
import ApiTelefonia from '../pages/ApiTelefonia/ApiTelefonia';
import ApiTelefoniaList from '../pages/ApiTelefonia/index';
import ApiTelefoniaEdit from '../pages/ApiTelefonia/ApiTelefoniaEdit';
import Pretickets from '../pages/Tickets/PreTickets';
import PreticketsAdd from '../pages/Tickets/PreticketsAdd';
import CategoriasConhecimento from '../pages/Knowledge/knowledgeCategorias';
import ConhecimentoArtigos from '../pages/Knowledge/knowledgeArticles';
import Suggestions from '../pages/Suggestions/SuggestionsList';

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/404" element={<Page404 />} />
      <Route path="/403" element={<Page403 />} />
      <Route path="*" element={<Navigate to="/404" />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<Home />} />
      </Route>
      <Route path="/users" element={<PrivateRoute />}>
        <Route path="/users" element={<Users />} />
      </Route>
      <Route path="/users/:id/edit" element={<PrivateRoute />}>
        <Route path="/users/:id/edit" element={<User />} />
      </Route>
      <Route path="/users/:id/company" element={<PrivateRoute />}>
        <Route path="/users/:id/company" element={<UserCompany />} />
      </Route>
      <Route path="/users/:id/access" element={<PrivateRoute />}>
        <Route path="/users/:id/access" element={<AccessUser />} />
      </Route>
      <Route path="/users/:id/profile" element={<PrivateRoute />}>
        <Route path="/users/:id/profile" element={<UserProfile />} />
      </Route>
      <Route path="/users/new" element={<PrivateRoute />}>
        <Route path="/users/new" element={<User />} />
      </Route>
      <Route path="/profile/:id" element={<PrivateRoute />}>
        <Route path="/profile/:id" element={<UserProfile />} />
      </Route>
      <Route path="/companies" element={<PrivateRoute />}>
        <Route path="/companies" element={<Companies />} />
      </Route>
      <Route path="/companies/:id/edit" element={<PrivateRoute />}>
        <Route path="/companies/:id/edit" element={<Company />} />
      </Route>
      <Route path="/companies/:id/access" element={<PrivateRoute />}>
        <Route path="/companies/:id/access" element={<AccessCompany />} />
      </Route>
      <Route path="/companies/new" element={<PrivateRoute />}>
        <Route path="/companies/new" element={<Company />} />
      </Route>
      <Route path="/accesses" element={<PrivateRoute />}>
        <Route path="/accesses" element={<Accesses />} />
      </Route>
      <Route path="/accesses/:id/edit" element={<PrivateRoute />}>
        <Route path="/accesses/:id/edit" element={<Access />} />
      </Route>
      <Route path="/accesses/:id/module" element={<PrivateRoute />}>
        <Route path="/accesses/:id/module" element={<AccessModule />} />
      </Route>
      <Route path="/accesses/new" element={<PrivateRoute />}>
        <Route path="/accesses/new" element={<Access />} />
      </Route>
      <Route path="/channels/" element={<PrivateRoute />}>
        <Route path="/channels/" element={<Channels />} />
      </Route>
      <Route path="/channels/new" element={<PrivateRoute />}>
        <Route path="/channels/new" element={<Channel />} />
      </Route>
      <Route path="/channels/:id/edit" element={<PrivateRoute />}>
        <Route path="/channels/:id/edit" element={<Channel />} />
      </Route>
      <Route path="/classifiers" element={<PrivateRoute />}>
        <Route path="/classifiers" element={<Classifiers />} />
      </Route>
      <Route path="/classifiers/new" element={<PrivateRoute />}>
        <Route path="/classifiers/new" element={<Classifier />} />
      </Route>
      <Route path="/classifiers/:id/edit" element={<PrivateRoute />}>
        <Route path="/classifiers/:id/edit" element={<Classifier />} />
      </Route>
      <Route path="/customers" element={<PrivateRoute />}>
        <Route path="/customers" element={<Customers />} />
      </Route>
      <Route path="/customers/new" element={<PrivateRoute />}>
        <Route path="/customers/new" element={<Customer />} />
      </Route>
      <Route path="/customers/:id/edit" element={<PrivateRoute />}>
        <Route path="/customers/:id/edit" element={<Customer />} />
      </Route>
      <Route path="/leads" element={<PrivateRoute />}>
        <Route path="/leads" element={<Leads />} />
      </Route>
      <Route path="/leads/new" element={<PrivateRoute />}>
        <Route path="/leads/new" element={<Lead />} />
      </Route>
      <Route path="/leads/:id/edit" element={<PrivateRoute />}>
        <Route path="/leads/:id/edit" element={<Lead />} />
      </Route>
      <Route path="/emailsproviders" element={<PrivateRoute />}>
        <Route path="/emailsproviders" element={<EmailsProviders />} />
      </Route>
      <Route path="/emailsproviders/new" element={<PrivateRoute />}>
        <Route path="/emailsproviders/new" element={<EmailProvider />} />
      </Route>
      <Route path="/emailsproviders/:id/edit" element={<PrivateRoute />}>
        <Route path="/emailsproviders/:id/edit" element={<EmailProvider />} />
      </Route>
      <Route path="/tickets" element={<PrivateRoute />}>
        <Route path="/tickets" element={<Tickets />} />
      </Route>
      <Route path="/tickets/new" element={<PrivateRoute />}>
        <Route path="/tickets/new" element={<TicketAdd />} />
      </Route>
      <Route path="/tickets" element={<PrivateRoute />}>
        <Route path="/tickets" element={<Ticket />} />
      </Route>
      <Route path="/tickets/:id/edit" element={<PrivateRoute />}>
        <Route path="/tickets/:id/edit" element={<Ticket />} />
      </Route>

      <Route path="/departaments" element={<PrivateRoute />}>
        <Route path="/departaments" element={<Departaments />} />
      </Route>
      <Route path="/departaments/new" element={<PrivateRoute />}>
        <Route path="/departaments/new" element={<Departament />} />
      </Route>
      <Route path="/departaments" element={<PrivateRoute />}>
        <Route path="/departaments" element={<Departament />} />
      </Route>
      <Route path="/departaments/:id/module" element={<PrivateRoute />}>
        <Route
          path="/departaments/:id/module"
          element={<AccessDepartament />}
        />
      </Route>
      <Route path="/requesters" element={<PrivateRoute />}>
        <Route path="/requesters" element={<Requesters />} />
      </Route>
      <Route path="/requesters/:id/edit" element={<PrivateRoute />}>
        <Route path="/requesters/:id/edit" element={<RequesterEdit />} />
      </Route>
      <Route path="/requesters/new" element={<PrivateRoute />}>
        <Route path="/requesters/new" element={<Requester />} />
      </Route>
      <Route path="/templates/" element={<PrivateRoute />}>
        <Route path="/templates/" element={<EmailTeamplates />} />
      </Route>
      <Route path="/servicedesk/" element={<PrivateRoute />}>
        <Route path="/servicedesk/" element={<ServiceDeskList />} />
      </Route>
      <Route path="/servicedesk/new" element={<PrivateRoute />}>
        <Route path="/servicedesk/new" element={<ServiceDesk />} />
      </Route>
      <Route path="/servicedesk/:id/edit" element={<PrivateRoute />}>
        <Route path="/servicedesk/:id/edit" element={<ServiceDeskEdit />} />
      </Route>
      <Route path="/priorities/" element={<PrivateRoute />}>
        <Route path="/priorities/" element={<PriorityList />} />
      </Route>
      <Route path="/stages/" element={<PrivateRoute />}>
        <Route path="/stages/" element={<StagesList />} />
      </Route>
      <Route path="/messagetemplates/" element={<PrivateRoute />}>
        <Route path="/messagetemplates/" element={<MessageTeamplate />} />
      </Route>
      <Route path="/reports/" element={<PrivateRoute />}>
        <Route path="/reports/" element={<Reports />} />
      </Route>
      <Route path="/apitelefonia/new" element={<PrivateRoute />}>
        <Route path="/apitelefonia/new" element={<ApiTelefonia />} />
      </Route>
      <Route path="/apitelefonia/" element={<PrivateRoute />}>
        <Route path="/apitelefonia/" element={<ApiTelefoniaList />} />
      </Route>
      <Route path="/apitelefonia/:id/edit" element={<PrivateRoute />}>
        <Route path="/apitelefonia/:id/edit" element={<ApiTelefoniaEdit />} />
      </Route>
      <Route path="/pretickets/" element={<PrivateRoute />}>
        <Route path="/pretickets/" element={<Pretickets />} />
      </Route>
      <Route path="/pretickets/new" element={<PrivateRoute />}>
        <Route path="/pretickets/new" element={<PreticketsAdd />} />
      </Route>

      <Route path="/categoriasKnowledge" element={<PrivateRoute />}>
        <Route
          path="/categoriasKnowledge"
          element={<CategoriasConhecimento />}
        />
      </Route>
      <Route path="/categoriasKnowledge/new" element={<PrivateRoute />}>
        <Route
          path="/categoriasKnowledge/new"
          element={<CategoriasConhecimento />}
        />
      </Route>
      <Route path="/conhecimentos" element={<PrivateRoute />}>
        <Route path="/conhecimentos" element={<ConhecimentoArtigos />} />
      </Route>
      <Route path="/conhecimentos/new" element={<PrivateRoute />}>
        <Route path="/conhecimentos/new" element={<ConhecimentoArtigos />} />
      </Route>
      <Route path="/conhecimentos/:id/edit" element={<PrivateRoute />}>
        <Route
          path="/conhecimentos/:id/edit"
          element={<ConhecimentoArtigos />}
        />
      </Route>
      <Route path="/suggestions" element={<PrivateRoute />}>
        <Route path="/suggestions" element={<Suggestions />} />
      </Route>
    </Routes>
  );
}
