import React, { useState, useEffect, useCallback } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { get } from 'lodash';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Loading from '../../components/Loading';
import Image from '../../components/CustomImage';
import axios from '../../services/axios';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useNotification } from '../../components/Notification/NotificationContext';
import Forms from '../../components/Forms';
import ErrorProcessor from '../../components/ErrorProcessor';

export default function User() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const notification = useNotification();
  const navigate = useNavigate();
  const params = useParams();
  const id = get(params, 'id', '');
  const [isLoading, setIsLoading] = useState(false);
  const [photo, setPhoto] = useState('');
  const [companies, setCompanies] = useState([]);
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    company: '',
    telefone: '',
    celular: '',
    ramal: '',
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Nome deve conter no mínimo 3 caracteres.')
      .required('Nome obrigatório.'),
    email: !id
      ? Yup.string().required('E-mail obrigatório.').email('E-mail inválido.')
      : Yup.string(),
    company: Yup.string().required('Empresa obrigatória.'),
    telefone: Yup.string()
      .matches(/^[0-9]{10}$/, 'Telefone deve conter 10 dígitos.')
      .required('Telefone obrigatório.'),
    celular: Yup.string()
      .matches(/^[0-9]{11}$/, 'Celular deve conter 11 dígitos.')
      .required('Celular obrigatório.'),
  });

  const handleFieldChange = useCallback((fieldName, value) => {
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
  }, []);

  const fields = [
    {
      name: 'name',
      id: 'name',
      label: 'Nome',
      type: 'text',
      onChange: (e) => handleFieldChange('name', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
    {
      name: 'email',
      id: 'email',
      label: 'E-mail',
      type: 'email',
      groupText: '@',
      onChange: (e) => handleFieldChange('email', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
    {
      name: 'company',
      id: 'company',
      label: 'Empresa',
      type: 'select',
      options: [
        { value: '', label: '--Selecione--' },
        ...companies.map((company) => ({
          value: company.id,
          label: company.razaoSocial,
        })),
      ],
      onChange: (e) => handleFieldChange('company', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
    {
      name: 'telefone',
      id: 'telefone',
      label: 'Telefone',
      type: 'text',
      onChange: (e) => handleFieldChange('telefone', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
    {
      name: 'celular',
      id: 'celular',
      label: 'Celular',
      type: 'text',
      onChange: (e) => handleFieldChange('celular', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
    {
      name: 'ramal',
      id: 'ramal',
      label: 'Ramal',
      type: 'text',
      onChange: (e) => handleFieldChange('ramal', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
  ];

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let action = 'cadastrado';

      if (id) {
        await axios.put(`/requesters/${id}`, formValues);
        action = 'atualizado';
      } else {
        await axios.post(`/requesters/`, formValues);
      }
      notification(`Solicitante ${action} com sucesso`, 'success');
      navigate('/requesters');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const { data } = await axios.get('/customers');
        setCompanies(data);
      } catch (err) {
        setApiErrors(err);
      }
    };

    fetchCompanies();

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);
        const { data } = await axios.get(`requesters/${id}`);
        const photoUrl = get(data, 'UserProfile.photoUrl', '');
        setPhoto(photoUrl);
        setFormValues({
          ...data,
          name: data.UserProfile.name,
          email: data.email,
          company: data.company,
          telefone: data.telefone,
          celular: data.celular,
          ramal: data.ramal,
        });
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
        setDataLoaded(true);
      }
    }

    getData();
  }, [id, notification, navigate]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs
        title={id ? 'Atualizar' : 'Cadastrar'}
        pages={[{ link: '/requesters', name: 'Solicitantes' }]}
      />
      <section>
        <Row>
          {id && (
            <Col lg={4}>
              <Card className="card-profile mb-4">
                <Card.Header
                  className="overflow-hidden"
                  style={{
                    backgroundImage: `url('/img/photos/greenShapes.jpg')`,
                  }}
                />
                <Card.Body className="text-center">
                  <div
                    className="card-profile-img mx-auto d-flex align-center"
                    style={{ maxHeight: '8rem' }}
                  >
                    <div className="position-relative overflow-hidden rounded-circle">
                      <Link
                        to={`/profile/${id}`}
                        title="Clique para alterar a foto"
                      >
                        <Image
                          src={photo || '/img/user-profile-circle.png'}
                          layout="fixed"
                          width={122}
                          height={122}
                          priority
                          alt={formValues.name}
                        />
                      </Link>
                    </div>
                  </div>

                  <h3 className="mb-3">{formValues.name}</h3>
                  <p className="mb-4">{formValues.email}</p>
                </Card.Body>
              </Card>
            </Col>
          )}
          <Col lg={id ? 8 : 12}>
            <Card className="mb-5">
              <Card.Header>
                <h4 className="card-heading">
                  {id ? 'Atualizar solicitante' : 'Cadastrar solicitante'}
                </h4>
              </Card.Header>
              <Card.Body>
                <ErrorProcessor error={apiErrors} />
                {!id || dataLoaded ? (
                  <Forms
                    initialValues={formValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    fields={fields}
                    btnSubmit={{
                      variant: 'primary',
                      type: 'submit',
                      text: id ? 'Atualizar' : 'Cadastrar',
                    }}
                  />
                ) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
