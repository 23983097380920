import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { Container, Card, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import DataTable from '../../components/DataTable';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import { useNotification } from '../../components/Notification/NotificationContext';
import ErrorProcessor from '../../components/ErrorProcessor';
import CardHeaderMore from '../../components/Cards/CardHeaderMore';

export default function Customers() {
  const routeName = '/customers';
  const dataName = 'Cliente';
  const titleName = 'Clientes';
  const navigate = useNavigate();
  const notification = useNotification();
  const userData = useSelector((state) => state.auth.user);

  const [apiErrors, setApiErrors] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  // Definição das colunas para o DataTable
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'nomeEmpresa',
      },
      {
        Header: 'Razão Social',
        accessor: 'razaoSocial',
      },
      {
        Header: 'CPF / CNPJ',
        accessor: 'cpfCnpj',
      },
      {
        Header: 'Ações',
        accessor: 'actions',
        noSearch: true,
        disableSortBy: true,
      },
    ],
    []
  );

  // Carrega os dados dos clientes
  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);
        const response = await axios.get(routeName);
        setData(response.data);
      } catch (err) {
        setApiErrors(err);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [notification]);

  // Função para remoção de cliente
  const confirmDelete = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        await axios.delete(`${routeName}/${id}`);
        notification(`${dataName} removido com sucesso`, 'success');
        const updated = data.filter((client) => client.id !== id);
        setData(updated);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    [data, notification]
  );

  // Filtra os dados com base no termo de pesquisa
  const filteredData = useMemo(() => {
    if (!searchText) return data;
    const search = searchText.toLowerCase();
    return data.filter(
      (item) =>
        (item.nomeEmpresa && item.nomeEmpresa.toLowerCase().includes(search)) ||
        (item.razaoSocial && item.razaoSocial.toLowerCase().includes(search)) ||
        (item.cpfCnpj && item.cpfCnpj.toLowerCase().includes(search))
    );
  }, [data, searchText]);

  // Mapeia os dados filtrados para o formato esperado pelo DataTable
  const tableData = useMemo(() => {
    return filteredData.map((dataObject) => {
      const linksAction = [
        {
          onclick: () => {
            navigate(`${routeName}/${dataObject.id}/edit`);
          },
          faicon: 'faEdit',
          text: 'Atualizar',
        },
      ];

      if (userData.isadmin === 1) {
        linksAction.push({
          custom: (
            <ButtonModal
              onConfirm={() => confirmDelete(dataObject.id)}
              message={`Confirma remoção do ${dataName} ${dataObject.razaoSocial}?`}
              buttonProps={{ text: 'Remover', faicon: 'faTrash' }}
              textModal={{
                title: `Remover ${dataName}`,
                textConfirm: 'Remover',
              }}
              dropdown
            />
          ),
        });
      }
      return {
        nomeEmpresa: dataObject.nomeEmpresa,
        razaoSocial: dataObject.razaoSocial,
        cpfCnpj: dataObject.cpfCnpj,
        actions: <CardHeaderMore links={linksAction} className="" />,
      };
    });
  }, [filteredData, userData.isadmin, navigate, confirmDelete]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs title={titleName} />

      {/* Cabeçalho com título e botão de cadastro */}
      <div className="page-header d-flex justify-content-between align-items-center">
        <h1 className="page-heading">{titleName}</h1>
        <div>
          <Link to={`${routeName}/new`}>
            <Button variant="primary" className="text-uppercase">
              <FaPlus /> Cadastrar
            </Button>
          </Link>
        </div>
      </div>

      {/* Campo de pesquisa com largura reduzida */}
      <Form.Group controlId="search" className="mb-3">
        <strong>Pesquisar</strong>
        <Form.Control
          type="search"
          placeholder="Digite aqui..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ maxWidth: '150px' }}
        />
      </Form.Group>

      <section>
        <Card className="card-table mb-5">
          <ErrorProcessor error={apiErrors} />
          <DataTable
            items={tableData}
            columns={tableColumns}
            defaultPageSize={5}
          />
        </Card>
      </section>
    </Container>
  );
}
