/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { Container, Card, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';

import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import DataTable from '../../components/DataTable';
import ErrorProcessor from '../../components/ErrorProcessor';
import CardHeaderMore from '../../components/Cards/CardHeaderMore';

moment.locale('pt-br');

/**
 * Coluna customizada para o "Nº Ticket", que redireciona ao clicar no ID.
 */
function TicketNumberCell({ row }) {
  TicketNumberCell.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.number.isRequired,
        ticketNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }).isRequired,
    }).isRequired,
  };

  const { id, ticketNumber } = row.original;

  return (
    <Link to={`/tickets/${id}/edit`}>
      {`${String(ticketNumber).padStart(5, '0')}`}
    </Link>
  );
}

export default function Tickets() {
  const routeName = '/tickets';
  const titleName = 'Tickets';
  const navigate = useNavigate();

  const [apiErrors, setApiErrors] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const userData = useSelector((state) => state.auth.user);

  // Quais colunas o usuário quer exibir
  const [selectedColumns, setSelectedColumns] = useState([
    'ticketNumber',
    'title',
    'department',
    'assignedTo',
    'vencAtendimento',
    'vencSolucao',
    'status',
  ]);

  // Campo de pesquisa
  const [searchText, setSearchText] = useState('');

  /**
   * Definimos a função de renderização da célula do Nº Ticket.
   * Isso faz o ticketNumber virar um link clicável para os detalhes.
   */
  const renderTicketNumberCell = useCallback(
    ({ row }) => <TicketNumberCell row={row} />,
    []
  );

  // Definimos todas as colunas disponíveis
  const allColumns = useMemo(() => {
    return [
      {
        Header: 'Nº Ticket',
        accessor: 'ticketNumber',
        Cell: renderTicketNumberCell,
      },
      { Header: 'Título', accessor: 'title' },
      { Header: 'Solicitante', accessor: 'requester' },
      { Header: 'Prioridade', accessor: 'priority' },
      { Header: 'Departamento', accessor: 'department' },
      { Header: 'Responsável', accessor: 'assignedTo' },
      { Header: 'Criado em', accessor: 'createdAt' },
      { Header: 'Venc Atendimento', accessor: 'vencAtendimento' },
      { Header: 'Venc Solução', accessor: 'vencSolucao' },
      { Header: 'Status', accessor: 'status' },
    ];
  }, [renderTicketNumberCell]);

  // Filtra as colunas selecionadas (exceto a de ações, que sempre aparece)
  const tableColumns = useMemo(() => {
    return allColumns.filter(
      (col) =>
        selectedColumns.includes(col.accessor) || col.accessor === 'actions'
    );
  }, [selectedColumns, allColumns]);

  // Marcar/desmarcar colunas
  const handleColumnSelection = (e) => {
    const { value, checked } = e.target;
    setSelectedColumns((prev) =>
      checked ? [...prev, value] : prev.filter((col) => col !== value)
    );
  };

  // Carrega os dados dos tickets
  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const [
        ticketsResponse,
        usersResponse,
        requestersResponse,
        userDepartmentsResponse,
        prioritiesResponse,
      ] = await Promise.all([
        axios.get(`${routeName}/`),
        axios.get(`/users`),
        axios.get(`/customers`),
        axios.get(`/userdpto`),
        axios.get(`/priorities`),
      ]);

      const userDepartments = userDepartmentsResponse.data.filter(
        (userDepartment) => userDepartment.userId === userData.id
      );
      const userDepartmentIds = userDepartments.map(
        (userDepartment) => userDepartment.departmentId
      );

      const departmentsMap = userDepartments.reduce((acc, dept) => {
        acc[dept.departmentId] = dept.Department
          ? dept.Department.name
          : 'Não disponível';
        return acc;
      }, {});

      const usersMap = usersResponse.data.reduce((acc, user) => {
        acc[user.id] = user.UserProfile
          ? user.UserProfile.name
          : 'Nome não disponível';
        return acc;
      }, {});

      const requestersMap = requestersResponse.data.reduce(
        (acc, razaoSocial) => {
          const names = razaoSocial.razaoSocial.split(' ');
          acc[razaoSocial.id] = `${names[0]} ${names[1] || ''}`.trim();
          return acc;
        },
        {}
      );

      const prioritiesMap = prioritiesResponse.data.reduce((acc, priority) => {
        acc[priority.id] = priority.name;
        return acc;
      }, {});

      const formattedData = await Promise.all(
        ticketsResponse.data
          .filter((ticket) => {
            // Filtro: se o ticket estiver no departamento do user ou atribuído ao user
            const inUserDepartment = userDepartmentIds.includes(
              ticket.department
            );
            const assignedToUser = ticket.assignedTo === userData.id;

            // Se for admin, vê tudo; senão, tickets não fechados do depto ou atribuído
            const shouldShow =
              userData.isadmin === 1
                ? true
                : ticket.status !== 'fechado' &&
                  (inUserDepartment || assignedToUser);

            return shouldShow;
          })
          .map(async (ticket) => {
            // Buscamos o serviceDesk para obter dados de prioridade e SLA
            const serviceDeskResponse = await axios.get(
              `/servicedesks/${ticket.serviceDesk}`
            );
            const serviceDesk = serviceDeskResponse.data;

            const priority = serviceDesk.priorities.find(
              (p) => p.id === ticket.priority
            );
            const atendimentoVencimento = priority
              ? priority.atendimentoVencimento
              : null;
            const solucaoVencimento = priority
              ? priority.solucaoVencimento
              : null;

            let vencAtendimento = 'Não iniciado';
            if (atendimentoVencimento) {
              const createdTime = moment(ticket.createdAt).tz(
                'America/Sao_Paulo'
              );
              const deadline = createdTime
                .clone()
                .add(moment.duration(atendimentoVencimento));
              const currentTime = moment().tz('America/Sao_Paulo');

              if (ticket.startTime) {
                const startTime = moment(ticket.startTime).tz(
                  'America/Sao_Paulo'
                );
                const timeDiff = startTime.diff(createdTime, 'hours', true);
                vencAtendimento = currentTime.isBefore(deadline)
                  ? `✔️ SLA cumprido (${timeDiff.toFixed(0)}h)`
                  : `❌ SLA vencido (${timeDiff.toFixed(0)}h)`;
              } else {
                const remainingTime = deadline.fromNow(true);
                vencAtendimento = currentTime.isBefore(deadline)
                  ? `⏳ Vence em ${remainingTime}`
                  : `❌ Atrasado há ${remainingTime}`;
              }
            }

            let vencSolucao = 'Não iniciado';
            if (ticket.startTime && solucaoVencimento) {
              const startTime = moment(ticket.startTime).tz(
                'America/Sao_Paulo'
              );
              const solutionDeadline = startTime
                .clone()
                .add(moment.duration(solucaoVencimento));
              const currentTime = moment().tz('America/Sao_Paulo');

              if (ticket.closingTime) {
                const closingTime = moment(ticket.closingTime).tz(
                  'America/Sao_Paulo'
                );
                const timeToSolve = closingTime.diff(startTime, 'hours', true);
                vencSolucao = closingTime.isBefore(solutionDeadline)
                  ? `✔️ SLA cumprido (${timeToSolve.toFixed(0)}h)`
                  : `❌ SLA vencido (${timeToSolve.toFixed(0)}h)`;
              } else {
                const remainingTime = solutionDeadline.fromNow(true);
                vencSolucao = currentTime.isBefore(solutionDeadline)
                  ? `⏳ Vence em ${remainingTime}`
                  : `❌ Atrasado há ${remainingTime}`;
              }
            }

            return {
              ...ticket,
              ticketNumber: ticket.id,
              title: ticket.title,
              requester: requestersMap[ticket.company],
              priority: prioritiesMap[ticket.priority] || ticket.priority,
              assignedTo: usersMap[ticket.assignedTo],
              status: ticket.status,
              createdAt: moment(ticket.createdAt)
                .tz('America/Sao_Paulo')
                .format('DD/MM/YYYY HH:mm:ss'),
              vencAtendimento,
              vencSolucao,
              department: departmentsMap[ticket.department] || 'Não disponível',
            };
          })
      );

      setData(formattedData);
      setDataLoaded(true);
    } catch (err) {
      setApiErrors(err);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [userData.id, userData.isadmin]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getData();
    const interval = setInterval(() => {
      getData();
    }, 60000); // a cada 15 segundos

    return () => clearInterval(interval);
  }, [getData]);

  // Aplica o filtro de pesquisa e prepara a data final para o DataTable
  const tableData = useMemo(() => {
    return data
      .filter((ticket) =>
        Object.values(ticket).some((value) =>
          String(value).toLowerCase().includes(searchText.toLowerCase())
        )
      )
      .map((ticketObj) => {
        const linksAction = [
          {
            onclick: () => {
              navigate(`${routeName}/${ticketObj.id}/edit`);
            },
            faicon: 'faEdit',
            text: 'Detalhes',
          },
        ];

        const statusName =
          ticketObj.status === 'aberto' ? (
            <b className="text-success">Aberto</b>
          ) : ticketObj.status === 'em_atendimento' ? (
            <b className="text-warning">Em Atendimento</b>
          ) : ticketObj.status === 'pausado' ? (
            <b className="text-primary">Em Pausa</b>
          ) : (
            <b className="text-danger">Fechado</b>
          );

        return {
          ...ticketObj,
          status: statusName,
          actions: (
            <div className="d-flex justify-content-between">
              <CardHeaderMore links={linksAction} className="" />
            </div>
          ),
        };
      });
  }, [data, searchText, navigate]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs title={titleName} />

      {/* Cabeçalho com título e botão */}
      <div className="page-header d-flex flex-wrap justify-content-between align-items-center mb-3">
        <h1 className="page-heading">{titleName}</h1>
        <div>
          <Link to={`${routeName}/new`}>
            <Button variant="primary" className="text-uppercase">
              <FaPlus /> Novo Ticket
            </Button>
          </Link>
        </div>
      </div>

      {/* Campo de pesquisa */}
      <Form.Group controlId="search" className="mb-3">
        <strong>Pesquisar</strong>
        <Form.Control
          type="search"
          placeholder="Digite aqui..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ maxWidth: '150px' }}
        />
      </Form.Group>

      {/* Seção para seleção das colunas */}
      <Card className="mb-3">
        <Card.Body>
          <Form>
            <Form.Label>Exibir colunas:</Form.Label>
            <div>
              {allColumns.map((col) => (
                <Form.Check
                  inline
                  key={col.accessor}
                  label={col.Header}
                  value={col.accessor}
                  checked={selectedColumns.includes(col.accessor)}
                  onChange={handleColumnSelection}
                />
              ))}
            </div>
          </Form>
        </Card.Body>
      </Card>

      <section>
        {dataLoaded && (
          <Card className="card-table mb-12">
            <ErrorProcessor error={apiErrors} />
            {/* .table-responsive => rolagem horizontal se a tabela for maior que a tela */}
            <div
              className="table-responsive"
              style={{ overflowX: 'auto', overflowY: 'hidden' }}
            >
              <DataTable
                className="table table-hover table-striped"
                style={{ minWidth: '100%', whiteSpace: 'nowrap' }}
                items={tableData}
                columns={tableColumns}
              />
            </div>
          </Card>
        )}
      </section>
    </Container>
  );
}
