import React, { useState, useEffect } from 'react';
import { Container, Card, Table, Button, Modal, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import 'react-quill/dist/quill.snow.css';

export default function SuggestionsList() {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  // Modal para criar/editar sugestão
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentSuggestion, setCurrentSuggestion] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    status: 'pending',
  });

  // Campo de pesquisa local
  const [searchText, setSearchText] = useState('');

  // Modal para exibir conteúdo completo (opcional)
  const [showContentModal, setShowContentModal] = useState(false);
  const [contentToShow, setContentToShow] = useState('');

  // Carrega as sugestões do backend
  const fetchSuggestions = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/suggestions');
      setSuggestions(response.data);
    } catch (error) {
      console.error('Erro ao carregar sugestões:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuggestions();
  }, []);

  // Abre modal de criação/edição
  const handleShowModal = () => setShowModal(true);

  // Fecha modal de criação/edição
  const handleCloseModal = () => {
    setShowModal(false);
    setEditMode(false);
    setCurrentSuggestion(null);
    setFormData({ title: '', description: '', status: 'pending' });
  };

  // Atualiza campos simples do formulário
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Atualiza campo de descrição (ReactQuill)
  const handleDescriptionChange = (value) => {
    setFormData((prev) => ({ ...prev, description: value }));
  };

  // Salva ou atualiza a sugestão
  const handleSave = async () => {
    setLoading(true);
    try {
      if (editMode && currentSuggestion) {
        // Edição
        await axios.put(`/suggestions/${currentSuggestion.id}`, formData);
      } else {
        // Criação
        await axios.post('/suggestions', formData);
      }
      fetchSuggestions();
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao salvar sugestão:', error);
    } finally {
      setLoading(false);
    }
  };

  // Prepara dados para edição
  const handleEdit = (suggestion) => {
    setCurrentSuggestion(suggestion);
    setFormData({
      title: suggestion.title,
      description: suggestion.description,
      status: suggestion.status,
    });
    setEditMode(true);
    handleShowModal();
  };

  // Deleta a sugestão
  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`/suggestions/${id}`);
      fetchSuggestions();
    } catch (error) {
      console.error('Erro ao deletar sugestão:', error);
    } finally {
      setLoading(false);
    }
  };

  // Muda o status (ex.: accepted, done) sem precisar abrir modal
  const handleStatusChange = async (suggestion, newStatus) => {
    setLoading(true);
    try {
      await axios.put(`/suggestions/${suggestion.id}`, { status: newStatus });
      fetchSuggestions();
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseContentModal = () => {
    setShowContentModal(false);
    setContentToShow('');
  };

  // Filtro local (busca em title e status)
  const filteredSuggestions = suggestions.filter((sug) => {
    const lowerSearch = searchText.toLowerCase();
    return (
      sug.title.toLowerCase().includes(lowerSearch) ||
      sug.status.toLowerCase().includes(lowerSearch)
    );
  });

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Breadcrumbs title="Sugestões" />

      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h1 className="page-heading">Sugestões</h1>
          <Button variant="success" onClick={handleShowModal}>
            + Sugestão
          </Button>
        </Card.Header>
        <Card.Body>
          {loading && <Loading />}

          {/* Campo de Pesquisa */}
          <Form.Group className="mb-3" controlId="searchText">
            <Form.Label>Pesquisar Sugestões</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite título ou status..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              style={{ maxWidth: '250px' }}
            />
          </Form.Group>

          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Título</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {filteredSuggestions.map((suggestion) => (
                  <tr key={suggestion.id}>
                    <td>{suggestion.title}</td>
                    <td>{suggestion.status}</td>
                    <td className="text-end" style={{ minWidth: '100%' }}>
                      <Button
                        variant="primary"
                        className="me-2"
                        onClick={() => handleEdit(suggestion)}
                      >
                        Visualizar
                      </Button>
                      <Button
                        variant="danger"
                        className="me-2"
                        onClick={() => handleDelete(suggestion.id)}
                      >
                        Remover
                      </Button>
                      {suggestion.status !== 'accepted' && (
                        <Button
                          variant="warning"
                          className="me-2"
                          onClick={() =>
                            handleStatusChange(suggestion, 'accepted')
                          }
                        >
                          Aceitar
                        </Button>
                      )}
                      {suggestion.status !== 'done' && (
                        <Button
                          variant="success"
                          onClick={() => handleStatusChange(suggestion, 'done')}
                        >
                          Concluir
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>

      {/* Modal de criar/editar sugestão */}
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? 'Editar Sugestão' : 'Adicionar Sugestão'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Descrição</Form.Label>
              <ReactQuill
                value={formData.description}
                onChange={handleDescriptionChange}
                style={{ height: '100%' }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="status"
                value={formData.status}
                onChange={handleInputChange}
              >
                <option value="pending">Pendente</option>
                <option value="accepted">Aceita</option>
                <option value="done">Concluída</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="success" onClick={handleSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para ver conteúdo completo (opcional) */}
      <Modal show={showContentModal} onHide={handleCloseContentModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Conteúdo Completo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            dangerouslySetInnerHTML={{ __html: contentToShow }}
            style={{ maxHeight: '70vh', overflowY: 'auto' }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseContentModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
