import React, { useState, useEffect } from 'react';
import { Container, Card, Table, Button, Modal, Form } from 'react-bootstrap';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';

export default function KnowledgeCategoryList() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
  });

  // Campo de pesquisa
  const [searchText, setSearchText] = useState('');

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/knowleadgecategories');
      setCategories(response.data);
    } catch (error) {
      console.error('Erro ao carregar categorias:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => {
    setShowModal(false);
    setEditMode(false);
    setFormData({ name: '', description: '' });
    setCurrentCategory(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveCategory = async () => {
    setLoading(true);
    try {
      if (editMode && currentCategory) {
        await axios.put(
          `/knowleadgecategories/${currentCategory.id}`,
          formData
        );
      } else {
        await axios.post('/knowleadgecategories', formData);
      }
      fetchCategories();
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao salvar categoria:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (category) => {
    setCurrentCategory(category);
    setFormData({
      name: category.name,
      description: category.description,
    });
    setEditMode(true);
    handleShowModal();
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`/knowleadgecategories/${id}`);
      fetchCategories();
    } catch (error) {
      console.error('Erro ao deletar categoria:', error);
    } finally {
      setLoading(false);
    }
  };

  // Filtro local dos itens, com base em nome e descrição
  const filteredCategories = categories.filter((cat) => {
    const lowerSearch = searchText.toLowerCase();
    const catName = cat.name.toLowerCase();
    const catDesc = cat.description ? cat.description.toLowerCase() : '';
    return catName.includes(lowerSearch) || catDesc.includes(lowerSearch);
  });

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Breadcrumbs title="Base de Conhecimento - Categorias" />
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h1 className="page-heading">Categorias</h1>
          <Button variant="success" onClick={handleShowModal}>
            + Categoria
          </Button>
        </Card.Header>

        <Card.Body>
          {loading && <Loading />}

          {/* Campo de Pesquisa */}
          <Form.Group className="mb-3" controlId="searchText">
            <Form.Label>
              <strong>Pesquisar</strong>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite um nome ou descrição..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              style={{ maxWidth: '250px' }}
            />
          </Form.Group>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Descrição</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {filteredCategories.map((category) => (
                <tr key={category.id}>
                  <td>{category.name}</td>
                  <td>{category.description}</td>
                  <td>
                    <Button
                      variant="primary"
                      className="me-2"
                      onClick={() => handleEdit(category)}
                    >
                      Editar
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(category.id)}
                    >
                      Remover
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? 'Editar Categoria' : 'Adicionar Categoria'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Descrição</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="success" onClick={handleSaveCategory}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
